/* eslint-disable */
import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import {FormattedMessage, injectIntl} from 'react-intl';

import MenuItem from '../../common/MenuItem'
import MobileMenu from '../../common/MobileMenu'
import LanguageSwitcher from '../../common/LanguageSwitcher'
import IsDesktop from '../../common/IsDesktop'
import IsMobile from '../../common/IsMobile'

import sendEvent from '../../../helpers/sendAnalytics'

import logo from '../../../assets/images/logo.png';
import diapersLogo from '../../../assets/images/diapers/royal-diapers-logo.png';

// White Images
import newBadge from '../../../assets/images/diapers/new.svg';
import newBadgeFr from '../../../assets/images/diapers/new-fr.svg';

const Container = styled.header`
  ${props => (props.front) ? tw`min-h-screen` : tw``}
`

const FloatMenu = styled.div`
  ${tw`pin-x pin-t absolute`}
  height: 120px;
  z-index: 2;

  @media only screen and (min-height:600px) and (max-height:700px) and (min-width:1200px) and (max-width:1300px) {
    height: 80px;
  }
`

const DefaultMenu = styled.div`
  height: 120px;
  position: relative;
  z-index: 10;

  @media only screen and (min-height:600px) and (max-height:700px) and (min-width:1200px) and (max-width:1300px) {
    height: 80px;
  }
`

const LogoWrapper = styled.div`
  position: relative;
  z-index: 99;
  margin-left: 50px;
  max-width: 160px;
  margin-top: 10px;
  img {
    width: 160px;
  }

  @media (max-width: 1366px) {
    margin: 10px;
    max-width: 100px;

    img {
      width: 100px;
    }
  }

  @media only screen and (min-height:600px) and (max-height:700px) and (min-width:1200px) and (max-width:1300px) {
    margin-top: 0;

    img {
      width: 100px;
    }
  }
`

const RightMenu = styled.div`
  ${tw`flex items-center relative`}
  width: 90%;
  z-index: 99;
  @media only screen and (min-height:600px) and (max-height:700px) and (min-width:1200px) and (max-width:1300px) {
    width: 90%;
  }
`

const Menu = styled.nav`
  ${tw`flex flex-row justify-between items-center`}

  @media only screen and (min-height:600px) and (max-height:700px) and (min-width:1200px) and (max-width:1300px) {
    height: 100%;
  }
`

const MenuUL = styled.ul`
  ${tw`flex items-center justify-start list-reset`}
  height: 35px;
  margin-left: auto;
  li {
    position: relative;
  }
`

const SubMenu = styled.ul`
  display:${props => props.show ? 'block':'none'};
  position: absolute;
  width: 250px;
  background: white;
  padding: 20px 0 0;
  margin-left: -10px;
  list-style: none;
  z-index: 99999 !important;
  a {
    text-align: left;
    display: block;
    padding: 10px 10px;
    transition: 0.3s;
    border: 0 !important;
    &:hover {
      border: 0 !important;
      background: #3490dc;
      color: white;
    }
  }
`

const MenuLI = styled.li`
  margin-left: 25px;
  position: relative;
  ${props => props.locale === 'fr' ? tw`text-center` : ''}
  a {
    font-size: 16px;
  }
  .new-badge {
    position: absolute;
    width: 63px;
    height: auto;
    top: -28px;
  }
`

const DividerFloat = styled.div`
  width: 1px;
  height: 35px;
  margin-right: 25px;
  margin-left: 25px;
  ${tw`bg-white`}
`

const Divider = styled.div`
  width: 1px;
  height: 35px;
  margin-right: 25px;
  margin-left: 25px;
  ${tw`bg-black`}
`

const MobileMenuWrapper = styled.div`
  ${tw`flex px-5 py-3 justify-between`}

  ${props => props.type === 'float' ? tw`absolute pin-x z-10` : null}
`

const HeaderContainer = styled(Container)`
  position: relative;
`

const types = {
  float: FloatMenu,
  default: DefaultMenu
}

const Header = ({ children, type, locale, urls, intl, secondLogo }) => {
  const [submenu,setSubmenu] = useState(false)
  const [submenu2,setSubmenu2] = useState(false)
  const MenuType = types[type];
  const menuItems = [
    {
      to: "08421dc2-480c-4c99-b6d5-834990b6fe7f",
      name: intl.formatMessage({ id:"menu.product" }),
      internal: true,
      target:'_self',
      submenu:true,
      key: "paper",
      new: false,
      subitems: [
        {
          to: '3f28be5e-63ac-434b-9371-8511b5defcaf',
          name: intl.formatMessage({ id:"submenu.bathroom_tissue" }),
          internal: true,
          target:'_self'
        },
        {
          to: 'fc61d565-257d-40b3-b1c4-34cac3f03193',
          name: intl.formatMessage({ id:"submenu.paper_towel" }),
          internal: true,
          target:'_self'
        },
        {
          to: '50063444-203d-4baf-8e1e-629613766fa1',
          name: intl.formatMessage({ id:"submenu.facial_tissue" }),
          internal: true,
          target:'_self'
        },
        {
          to: "be15610d-872f-4d9e-bb90-d93abc9d844b",
          name: intl.formatMessage({ id:"submenu.napkins" }),
          internal: true,
          target:'_self'
        }
      ]
    },
    {
      to: "e7532e8e-02be-45bd-922b-0d2f47285bc5",
      name: intl.formatMessage({ id:"menu.diapers" }),
      internal: true,
      target:'_self',
      submenu:false,
      key: null,
      new: true,
      subitems: [],

    },
    {
      to: locale == 'fr' ? "/fr/fierrrementcanadienne":"/purr-oudlycanadian",
      name: intl.formatMessage({ id:"menu.about" }),
      internal: false,
      target:'_self',
      submenu:false,
      key: null,
      new: false,
      subitems: []
    },
    {
      to: locale == 'fr' ? "/fr/carboneutre":"/carbon-neutral",
      name: intl.formatMessage({ id:"menu.carbon" }),
      internal: false,
      target:'_self',
      submenu:false,
      key: null,
      new: false,
      subitems: []
    },
    {
      to: locale == 'fr' ? "/fr/ami":"/pets",
      name: intl.formatMessage({ id:"menu.pets" }),
      internal: false,
      target:'_self',
      submenu:false,
      key: null,
      new: false,
      subitems: []
    },
    {
      to: "ee65cb8e-5cac-4013-bc5c-31d075d0ab89",
      name: intl.formatMessage({ id:"menu.buy" }),
      internal: true,
      target:'_self',
      submenu:true,
      key: "buy",
      new: false,
      subitems: [
        {
          to: 'ee65cb8e-5cac-4013-bc5c-31d075d0ab89',
          name: intl.formatMessage({ id:"menu.product" }),
          internal: true,
          target:'_self'
        },
        {
          to: locale == 'fr' ? "/fr/couches#buyonline":"/diapers#buyonline",
          name: intl.formatMessage({ id:"menu.diapers" }),
          internal: false,
          external: true,
          target:'_self'
        },
      ]
    }
  ]

  let logoLink = '/'

  if (intl.locale === 'fr') {
    logoLink = '/fr'
  }

  return (
    <HeaderContainer>
      <IsDesktop>
        <MenuType>
          <Menu>
            <LogoWrapper>
              <Link to={logoLink}>
              <img src={logo} alt="Royale" />
              </Link>
            </LogoWrapper>
            <RightMenu type={type}>
              <MenuUL>
                {menuItems.map(item => (
                  <>
                    <MenuLI
                      onMouseOver={item.submenu ? () => {
                        item.key == "paper" ?
                          setSubmenu(true)
                          :
                          setSubmenu2(true)
                    }:null}
                      onMouseOut={item.submenu ? () => {
                        item.key == "paper" ?
                          setSubmenu(false)
                          :
                          setSubmenu2(false)
                      }:null} locale={intl.locale} key={item.name}>
                      {item.new ?
                        <img src={intl.locale == 'fr' ? newBadgeFr:newBadge} className="new-badge" />:null
                        // <div className='coming-soon'><FormattedMessage id="diapers.comingsoon" /></div>:null
                      }
                      {item.internal
                        ?
                          <MenuItem to={item.to} type={type} disabled={item.submenu} open={item.key == "paper" ? submenu:submenu2} target={item.target} internal>{item.name}</MenuItem>
                        :
                          <MenuItem to={item.to} type={type} target={item.target}>{item.name}</MenuItem>
                      }
                      { item.submenu ?
                        <SubMenu show={item.key == "paper" ? submenu:submenu2} aria-haspopup="true" aria-expanded={item.key == "paper" ? submenu:submenu2}>
                          {item.subitems.map(subitem => (

                              subitem.internal ?
                                <li><MenuItem to={subitem.to} type={type} target={subitem.target} internal>{subitem.name}</MenuItem></li>
                                :
                                <li><MenuItem to={subitem.to} type={type} target={subitem.target}>{subitem.name}</MenuItem></li>

                          ))}
                        </SubMenu>
                        :null
                      }
                    </MenuLI>

                  </>

                ))}
              </MenuUL>
              {type === 'float' ? <DividerFloat /> : <Divider /> }
              <LanguageSwitcher urls={urls} locale={locale} type={type} />
            </RightMenu>
          </Menu>
        </MenuType>
      </IsDesktop>

      <IsMobile>
        <MobileMenuWrapper type={type}>
          <LogoWrapper>
            <Link to={intl.locale === 'en' ? '/' : '/fr'}>
             <img src={secondLogo ? diapersLogo : logo} alt="Royale" />
            </Link>
          </LogoWrapper>

          <MobileMenu
            menuItems={menuItems}
            locale={locale}
            urls={urls}
            type={type}
          />
        </MobileMenuWrapper>
      </IsMobile>

      {children}
    </HeaderContainer>
  )
}

Header.propTypes = {
  // Either float or default
  type: PropTypes.string,
  // Any additional menu elements (used for homepage)
  children: PropTypes.node,
  // Used to change languages
  urls: PropTypes.shape({
    en: PropTypes.string.isRequired,
    fr: PropTypes.string.isRequired
  }).isRequired,
  // used to determine current langage
  locale: PropTypes.string,
  secondLogo: PropTypes.bool
}

Header.defaultProps = {
  type: 'default',
  children: null,
  locale: 'en',
  secondLogo: false
}

export default injectIntl(Header)
